import { BrowserModule } from '@angular/platform-browser';
// import { TransferHttpCacheModule } from '@nguniversal/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ng6-toastr-notifications';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { FormsModule} from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";
import { AngularFireModule } from "@angular/fire";
import {AngularFireStorageModule} from "@angular/fire/storage";
import { environment } from 'src/environments/environment';




import { AppComponent } from './app.component';
import { headerComponent } from './SharedResources/Components/Header/header';
import { footerComponent } from './SharedResources/Components/Footer/footer';
import { homeComponent } from './pages/Home/home';
import { loaderModule } from './FeaturedModules/loader.module';
import { filterModule } from './FeaturedModules/filter.module';
import { LazyLoadDirective } from './SharedResources/pipes/lazyLoadingDirective';
import { jsonLDModule } from './FeaturedModules/jsonLD.module';
import { AppRoutes } from './app-routing.module';




@NgModule({
  declarations: [
    AppComponent,
    headerComponent,
    footerComponent,
    homeComponent,
    LazyLoadDirective
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    // TransferHttpCacheModule,
    BrowserAnimationsModule,
    AngularFireStorageModule,
    AngularFireModule.initializeApp(environment.firebaseConfig, "cloud"), 
    ToastrModule.forRoot(),
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot(AppRoutes,{preloadingStrategy:PreloadAllModules,onSameUrlNavigation: 'reload'}),
    loaderModule,
    filterModule,
    jsonLDModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


