import {Injectable} from '@angular/core';
import {apiServiceComponent} from './api.service';


@Injectable({providedIn: 'root'})
export class CartService{
    private url : string = "";

	constructor(private api : apiServiceComponent){
    }


    cartList(data?:any){
        if(!data){
            data={}
        }
        this.url = "cart";
        return this.api.post(this.url, data);
    }

    wishList(index,size){
        this.url = "wishlist";
        let query = "?index="+index+"&size="+size;
        return this.api.get(this.url, query);
    }

    insertWishlist(product_id,index,size){
        this.url = "wishlist";
        let query = "?product_id="+product_id+"&index="+index+"&size="+size;
        return this.api.get(this.url, query);
    }

    insertCart(data){
        this.url = "insert_cart";
        return this.api.post(this.url, data);
    }

    updateCart(data){
        this.url = "update_cart";
        return this.api.post(this.url, data);
    }

    couponCode(data){
        this.url = "coupon_cart";
        return this.api.post(this.url, data);
    }

    addAdditionalComment(data){
        this.url = "update_additional_comment";
        return this.api.post(this.url, data);
    }



}