import {Injectable} from '@angular/core';
import {apiServiceComponent} from './api.service';


@Injectable({providedIn: 'root'})
export class HeaderService{
    private url : string = "";

	constructor(private api : apiServiceComponent){
    }

    menu(){
        this.url = "menu";
		let query = "";
		return this.api.get(this.url, query);
    }

    footer(){
        this.url = "footer";
		let query = "";
		return this.api.get(this.url, query);
    }

    storeList(){
        this.url = "store_location_list";
		let query = "";
		return this.api.get(this.url, query);
    }

    search(data){
        this.url = "search";
		return this.api.post(this.url, data);   
    }

    logout(data){
        this.url = "logout";
		return this.api.post(this.url, data);
    }

}